import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "video", "imageDetails", "videoSetting", "styleType", "srcSetSetting", "imageOptimisationType", "isHero", "isGreaterThanPhone", "isLessThanDesktop", "mobileCardBackgroundColor", "mobileHeroVariation"];
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from './Media.style';
import Image from '../../atoms/Image';
import Video from '../../atoms/Video';
var Media = function Media(_ref) {
  var _imageDetails$image, _imageDetails$mobileI, _imageDetails$image3, _video$thumbnail, _video$thumbnail$imag, _video$thumbnail2, _video$thumbnail2$mob, _video$thumbnail2$mob2, _video$thumbnail3, _video$thumbnail3$mob, _video$thumbnail4, _video$thumbnail4$fie, _video$thumbnail4$fie2, _video$thumbnail4$fie3, _video$thumbnail4$fie4, _video$thumbnail5, _video$thumbnail5$mob, _video$thumbnail5$mob2, _video$thumbnail6, _video$thumbnail6$mob, _video$thumbnail7, _video$thumbnail7$ima, _video$thumbnail8, _video$thumbnail8$fie, _video$thumbnail8$fie2, _video$thumbnail8$fie3, _video$thumbnail8$fie4, _video$thumbnail9, _video$thumbnail9$ima, _video$thumbnail10, _video$thumbnail10$mo, _video$thumbnail10$mo2, _video$thumbnail11, _video$thumbnail11$mo, _video$thumbnail12, _video$thumbnail12$mo, _video$thumbnail12$mo2, _video$thumbnail13, _video$thumbnail13$mo, _video$thumbnail14, _video$thumbnail14$im;
  var className = _ref.className,
    video = _ref.video,
    imageDetails = _ref.imageDetails,
    videoSetting = _ref.videoSetting,
    styleType = _ref.styleType,
    srcSetSetting = _ref.srcSetSetting,
    imageOptimisationType = _ref.imageOptimisationType,
    isHero = _ref.isHero,
    isGreaterThanPhone = _ref.isGreaterThanPhone,
    isLessThanDesktop = _ref.isLessThanDesktop,
    mobileCardBackgroundColor = _ref.mobileCardBackgroundColor,
    mobileHeroVariation = _ref.mobileHeroVariation,
    others = _objectWithoutProperties(_ref, _excluded);
  var imageURL = imageDetails === null || imageDetails === void 0 ? void 0 : (_imageDetails$image = imageDetails.image) === null || _imageDetails$image === void 0 ? void 0 : _imageDetails$image.url;
  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (!imageURL) {
    var _imageDetails$image2, _imageDetails$image2$;
    imageURL = imageDetails === null || imageDetails === void 0 ? void 0 : (_imageDetails$image2 = imageDetails.image) === null || _imageDetails$image2 === void 0 ? void 0 : (_imageDetails$image2$ = _imageDetails$image2.file) === null || _imageDetails$image2$ === void 0 ? void 0 : _imageDetails$image2$.url;
  }
  var mobileImageURL = imageDetails === null || imageDetails === void 0 ? void 0 : (_imageDetails$mobileI = imageDetails.mobileImage) === null || _imageDetails$mobileI === void 0 ? void 0 : _imageDetails$mobileI.url;

  // in case there is no mobile image defined on Contentful
  var mobileImageFallbackURL = imageDetails === null || imageDetails === void 0 ? void 0 : (_imageDetails$image3 = imageDetails.image) === null || _imageDetails$image3 === void 0 ? void 0 : _imageDetails$image3.url;

  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (imageDetails && imageDetails.mobileImage && !mobileImageURL) {
    var _imageDetails$mobileI2, _imageDetails$mobileI3;
    mobileImageURL = imageDetails === null || imageDetails === void 0 ? void 0 : (_imageDetails$mobileI2 = imageDetails.mobileImage) === null || _imageDetails$mobileI2 === void 0 ? void 0 : (_imageDetails$mobileI3 = _imageDetails$mobileI2.file) === null || _imageDetails$mobileI3 === void 0 ? void 0 : _imageDetails$mobileI3.url;
  }
  return video ? isHero ? __jsx(Fragment, null, __jsx("div", {
    className: "nva-media ".concat(className, " video-container")
  }, __jsx(Video, _extends({
    className: "nva-media__video",
    url: video.url
  }, videoSetting, {
    isHero: isHero,
    isGreaterThanPhone: isGreaterThanPhone,
    isLessThanDesktop: isLessThanDesktop,
    mobileCardBackgroundColor: mobileCardBackgroundColor,
    mobileHeroVariation: mobileHeroVariation
  }))), __jsx(Image, {
    priority: srcSetSetting && srcSetSetting.priority,
    src: (video === null || video === void 0 ? void 0 : (_video$thumbnail = video.thumbnail) === null || _video$thumbnail === void 0 ? void 0 : (_video$thumbnail$imag = _video$thumbnail.image) === null || _video$thumbnail$imag === void 0 ? void 0 : _video$thumbnail$imag.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail2 = video.thumbnail) === null || _video$thumbnail2 === void 0 ? void 0 : (_video$thumbnail2$mob = _video$thumbnail2.mobileImage) === null || _video$thumbnail2$mob === void 0 ? void 0 : (_video$thumbnail2$mob2 = _video$thumbnail2$mob.file) === null || _video$thumbnail2$mob2 === void 0 ? void 0 : _video$thumbnail2$mob2.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail3 = video.thumbnail) === null || _video$thumbnail3 === void 0 ? void 0 : (_video$thumbnail3$mob = _video$thumbnail3.mobileImage) === null || _video$thumbnail3$mob === void 0 ? void 0 : _video$thumbnail3$mob.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail4 = video.thumbnail) === null || _video$thumbnail4 === void 0 ? void 0 : (_video$thumbnail4$fie = _video$thumbnail4.fields) === null || _video$thumbnail4$fie === void 0 ? void 0 : (_video$thumbnail4$fie2 = _video$thumbnail4$fie.image) === null || _video$thumbnail4$fie2 === void 0 ? void 0 : (_video$thumbnail4$fie3 = _video$thumbnail4$fie2.fields) === null || _video$thumbnail4$fie3 === void 0 ? void 0 : (_video$thumbnail4$fie4 = _video$thumbnail4$fie3.file) === null || _video$thumbnail4$fie4 === void 0 ? void 0 : _video$thumbnail4$fie4.url),
    mobileImageUrl: (video === null || video === void 0 ? void 0 : (_video$thumbnail5 = video.thumbnail) === null || _video$thumbnail5 === void 0 ? void 0 : (_video$thumbnail5$mob = _video$thumbnail5.mobileImage) === null || _video$thumbnail5$mob === void 0 ? void 0 : (_video$thumbnail5$mob2 = _video$thumbnail5$mob.file) === null || _video$thumbnail5$mob2 === void 0 ? void 0 : _video$thumbnail5$mob2.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail6 = video.thumbnail) === null || _video$thumbnail6 === void 0 ? void 0 : (_video$thumbnail6$mob = _video$thumbnail6.mobileImage) === null || _video$thumbnail6$mob === void 0 ? void 0 : _video$thumbnail6$mob.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail7 = video.thumbnail) === null || _video$thumbnail7 === void 0 ? void 0 : (_video$thumbnail7$ima = _video$thumbnail7.image) === null || _video$thumbnail7$ima === void 0 ? void 0 : _video$thumbnail7$ima.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail8 = video.thumbnail) === null || _video$thumbnail8 === void 0 ? void 0 : (_video$thumbnail8$fie = _video$thumbnail8.fields) === null || _video$thumbnail8$fie === void 0 ? void 0 : (_video$thumbnail8$fie2 = _video$thumbnail8$fie.image) === null || _video$thumbnail8$fie2 === void 0 ? void 0 : (_video$thumbnail8$fie3 = _video$thumbnail8$fie2.fields) === null || _video$thumbnail8$fie3 === void 0 ? void 0 : (_video$thumbnail8$fie4 = _video$thumbnail8$fie3.file) === null || _video$thumbnail8$fie4 === void 0 ? void 0 : _video$thumbnail8$fie4.url),
    isHero: isHero,
    alt: video.thumbnail.altText || (imageDetails === null || imageDetails === void 0 ? void 0 : imageDetails.altText),
    styleType: styleType,
    className: "nva-media__image ".concat(className, " mobile-image"),
    srcSetSettingImg: srcSetSetting,
    imageOptimisationType: imageOptimisationType,
    isLessThanDesktop: isLessThanDesktop,
    isGreaterThanPhone: isGreaterThanPhone,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  })) : __jsx(Fragment, null, __jsx("div", {
    className: "nva-media ".concat(className, " video-container")
  }, __jsx(Video, _extends({
    className: "nva-media__video",
    url: video.url
  }, videoSetting, {
    isHero: isHero,
    isGreaterThanPhone: isGreaterThanPhone,
    src: (video === null || video === void 0 ? void 0 : (_video$thumbnail9 = video.thumbnail) === null || _video$thumbnail9 === void 0 ? void 0 : (_video$thumbnail9$ima = _video$thumbnail9.image) === null || _video$thumbnail9$ima === void 0 ? void 0 : _video$thumbnail9$ima.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail10 = video.thumbnail) === null || _video$thumbnail10 === void 0 ? void 0 : (_video$thumbnail10$mo = _video$thumbnail10.mobileImage) === null || _video$thumbnail10$mo === void 0 ? void 0 : (_video$thumbnail10$mo2 = _video$thumbnail10$mo.file) === null || _video$thumbnail10$mo2 === void 0 ? void 0 : _video$thumbnail10$mo2.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail11 = video.thumbnail) === null || _video$thumbnail11 === void 0 ? void 0 : (_video$thumbnail11$mo = _video$thumbnail11.mobileImage) === null || _video$thumbnail11$mo === void 0 ? void 0 : _video$thumbnail11$mo.url),
    mobileImageUrl: (video === null || video === void 0 ? void 0 : (_video$thumbnail12 = video.thumbnail) === null || _video$thumbnail12 === void 0 ? void 0 : (_video$thumbnail12$mo = _video$thumbnail12.mobileImage) === null || _video$thumbnail12$mo === void 0 ? void 0 : (_video$thumbnail12$mo2 = _video$thumbnail12$mo.file) === null || _video$thumbnail12$mo2 === void 0 ? void 0 : _video$thumbnail12$mo2.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail13 = video.thumbnail) === null || _video$thumbnail13 === void 0 ? void 0 : (_video$thumbnail13$mo = _video$thumbnail13.mobileImage) === null || _video$thumbnail13$mo === void 0 ? void 0 : _video$thumbnail13$mo.url) || (video === null || video === void 0 ? void 0 : (_video$thumbnail14 = video.thumbnail) === null || _video$thumbnail14 === void 0 ? void 0 : (_video$thumbnail14$im = _video$thumbnail14.image) === null || _video$thumbnail14$im === void 0 ? void 0 : _video$thumbnail14$im.url),
    alt: video.thumbnail.altText || (imageDetails === null || imageDetails === void 0 ? void 0 : imageDetails.altText),
    title: video.thumbnail.title || video.thumbnail.altText || imageDetails && imageDetails.image && (imageDetails.title || imageDetails.altText),
    styleType: styleType,
    imgClassName: "nva-media__image ".concat(className, " mobile-image"),
    srcSetSettingImg: srcSetSetting,
    imageOptimisationType: imageOptimisationType,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  })))) : __jsx("div", {
    className: "nva-media ".concat(className)
  }, __jsx(Image, {
    className: "nva-media__image",
    priority: srcSetSetting && srcSetSetting.priority,
    src: imageURL,
    alt: imageDetails && imageDetails.altText,
    styleType: styleType,
    mobileImageUrl: mobileImageURL || mobileImageFallbackURL,
    srcSetSettingImg: srcSetSetting,
    title: imageDetails && imageDetails.image && (imageDetails.title || imageDetails.altText),
    imageOptimisationType: imageOptimisationType,
    isHero: isHero,
    isLessThanDesktop: isLessThanDesktop,
    isGreaterThanPhone: isGreaterThanPhone,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  }));
};
Media.defaultProps = {
  className: '',
  styleType: '',
  video: null,
  imageOptimisationType: ''
};
export default styled(Media).withConfig({
  componentId: "sc-dbhlap-0"
})(["", ";"], styles);
export { Media as MediaVanilla };